<template>
  <div>
    <!--Breadcrumb-->
    <section>

      <Breadcrumb
        title="Kayıt Ol"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'Kayıt Ol' },
        ]"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Login-Section-->
    <section class="sptb">
      <div class="container customerpage">
        <div class="row">
          <div class="col-lg-4 d-block mx-auto">
            <div class="row">
              <div class="col-xl-12 col-md-12 col-md-12">
                <div class="card mb-0">
                  <div class="card-header">
                    <h3 class="card-title">Kayıt Ol</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <label class="form-label text-dark">Kurum Adı</label>
                      <input
                        type="text"
                        name="fullname"
                        class="form-control"
                        placeholder="Kurum Adınızı Giriniz"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label text-dark">E-Posta</label>
                      <input
                        type="email"
                        name="email"
                        class="form-control"
                        placeholder="E-Posta Girin"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label text-dark">Parola</label>
                      <input
                        type="password"
                        class="form-control"
                        name="password"
                        id="exampleInputPassword1"
                        placeholder="Parola"
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label text-dark">Parola Tekrar</label>
                      <input
                        type="password"
                        name="repassword"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Parola Tekrar"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label class="form-label">Üyelik Türünüz:</label>
                      </div>
                      <div class="col-md-12">
                        <div class="custom-controls-stacked d-md-flex">
                          <label class="custom-control custom-radio me-2">
                            <input
                              type="radio"
                              class="custom-control-input"
                              name="memberType"
                              value="0"
                              @click="member"
                              v-model="memberType"
                              checked
                            />
                            <span class="custom-control-label">Danışan</span>
                          </label>
                          <label class="custom-control custom-radio me-2">
                            <input
                              type="radio"
                              class="custom-control-input"
                              name="memberType"
                              @click="expert"
                              v-model="memberType"
                              value="1"
                            />
                            <span class="custom-control-label">Terapist</span>
                          </label>
                          <label class="custom-control custom-radio me-2">
                            <input
                              type="radio"
                              class="custom-control-input"
                              name="memberType"
                              @click="center"
                              v-model="memberType"
                              value="2"
                            />
                            <span class="custom-control-label">Kurum</span>
                          </label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <label class="custom-control custom-check">
                          <input
                            style="margin-left: -23px"
                            type="checkbox"
                            class="form-check-input"
                            v-model="check"
                          />
                          <span class="form-check-label"
                            ><a
                              target="_blank"
                              href="/page-19-kurum-aydinlatma-metni"
                              >Kurum Aydınlatma Metni</a
                            >ni okudum, onaylıyorum.</span
                          >
                        </label>
                      </div>
                      <div class="col-md-12">
                        <label class="custom-control custom-check">
                          <input
                            style="margin-left: -23px"
                            type="checkbox"
                            class="form-check-input"
                            v-model="check2"
                          />
                          <span class="form-check-label"
                            ><a
                              target="_blank"
                              href="/page-20-hizmet-ve-kullanici-sozlesmesi"
                              >Hizmet ve Kullanıcı Sözleşmesini okudum, onaylıyorum.</a></span
                          >
                        </label>
                      </div>
                    </div>
                    
                    <div class="form-footer mt-2">
                      <button
                        @click="register"
                        id="registerButton"
                        class="btn btn-primary btn-block"
                      >
                        Kayıt Ol
                      </button>
                    </div>
                    <div class="text-center mt-3 text-dark">
                      Zaten üye misin?
                      <router-link to="/login">Giriş Sayfası!</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/Login-Section-->
  </div>
</template>
    <script>
import Breadcrumb from "../../components/Breadcrumb.vue";
export default {
  metaInfo() {
    return {
      title: "Kayıt ol - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Kayıt ol - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Kayıt ol - Terapi Vitrini",
      "Kayıt ol - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
    if (this.$store.state.isLogin) {
      this.$router.push("/home");
    }

    this.memberType = 2;
  },
  data() {
    return {
      pageGet: [],
      memberType: 0,
      check: 0,
      check2: 0,
    };
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    expert() {
        this.$router.push("/expert/register");
    },
    center() {
      this.$router.push("/register/center/page");
    },
    member() {
        this.$router.push("/register");
    },
    register() {
      let fullname = document.querySelector("input[name=fullname]").value;
      let email = document.querySelector("input[name=email]").value;
      let password = document.querySelector("input[name=password]").value;
      let repassword = document.querySelector("input[name=repassword]").value;
      if (password != repassword) {
        /**PAROLALAR EŞLEŞMİYOR */
        this.$vToastify.warning("Parolalar eşleşmiyor", "Uyarı!");
      } else if (email == null || email == "") {
        /**EMAİL BOŞ */
        this.$vToastify.warning("Mail adresi boş olamaz", "Uyarı!");
      } else if (fullname == null || fullname == "") {
        /**İSİM SOYİSİM BOŞ */
        this.$vToastify.warning("Tam adınızı girmediniz", "Uyarı!");
      } else if (password == null || password == "") {
        /**PAROLA BOŞ */
      } else if (repassword == null || repassword == "") {
        /**PAROLA YENİDEN BOŞ */
        this.$vToastify.warning("Parolanızı tekrar girmelisiniz", "Uyarı!");
      } else if (this.check == 0) {
        /**PAROLA YENİDEN BOŞ */
        this.$vToastify.warning(
          "Kullanım politikalarını onaylamadınız",
          "Uyarı!"
        );
      }
      else if (this.check2== 0) {
        /**PAROLA YENİDEN BOŞ */
        this.$vToastify.warning(
          "Hizmet ve kullanıcı sözleşmesini onaylamadınız",
          "Uyarı!"
        );
      } else {
        let registerInfo = {
          fullname: fullname,
          email: email,
          password: password,
          memberType: this.memberType,
        };
        document.getElementById("registerButton").disabled = true;
        document.getElementById("registerButton").value =
          "Bilgiler kontrol ediliyor..";
        this.$store.dispatch("userRegister", registerInfo).then((value) => {
          if (value.type == "success") {
            /**KAYIT BAŞARILI GİRİŞ EKRANINA YÖNLENDİR*/
            this.$vToastify.success(
              "Kayıt başarılı, giriş sayfasına yönlendiriliyorsunuz. Lütfen e-posta adresinizi kontrol ediniz",
              "Başarılı"
            );
            this.$router.push("/login");
          }
          if (value.type == "error") {
            document.getElementById("registerButton").disabled = false;
            document.getElementById("registerButton").value = "Kayıt Ol";
            if (value.code == "0xUMZV") {
              this.$vToastify.error(
                "E-posta adresi sistemde kayıtlı, lütfen giriş yapmayı deneyin",
                "Hata!"
              );
            }
            if (value.code == "0xERR") {
              this.$vToastify.error(
                "Sistemsel bir hata nedeniyle kaydınız gerçekleşemedi",
                "Hata!"
              );
            }
            if (value.code == "0xUDEL") {
              this.$vToastify.error("Bu mail adresi silinmiş!", "Hata!");
            }
          }
        });
      }
    },
  },
  components: { Breadcrumb },
  mounted() {
    this.backToTop();
  },
};
</script>